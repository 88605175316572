<template>

  <template>
    <NuxtLayout/>
  </template>

</template>
<script setup lang="ts">
useHead({
  titleTemplate: (titleChunk?: string) => {
    return titleChunk ? `${titleChunk} - Integrex.Space` : 'Integrex.Space';
  },
});
</script>
