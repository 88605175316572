export interface FeatureLink {
  rel: string;
  href: string;
  type: string; // content type
}

export interface FeatureLinkIssue<TContext> {
  target?: string,
  severity?: string,
  error?: {
    reason: string,
    code: string,
    domain: string,
    debug: {
      msg: string
    },
    context?: TContext
  };
  /**
   * linkRel
   */
  link: string;
}

export interface FeatureLinkDTO<TContext extends object = object> {
  _links: FeatureLink[];
  _issues: FeatureLinkIssue<TContext>[];
}

type LinkRef = string;
export interface ParsedLink<TContext extends object> {
  link: FeatureLink;
  issues?: FeatureLinkIssue<TContext>[];
}

export function findIssueByLink<TContext extends object>(issues: FeatureLinkIssue<TContext>[], link: FeatureLink): FeatureLinkIssue<TContext>[] {
  return issues.filter((e) => e && e.link === link.rel);
}

export class FeatureChecker<TContext extends object = object> {
  links: Record<LinkRef, ParsedLink<TContext>> = {};

  constructor(links: FeatureLink[], issues: FeatureLinkIssue<TContext>[]) {
    this.prepare(links, issues);
  }

  public hasLinkNoError(linkRef: LinkRef): boolean {
    const linkData = this.links[linkRef] || null;
    if (!linkData) {
      return false;
    }

    if (Array.isArray(linkData.issues) && linkData.issues.length > 0) {
      return false;
    }

    return true;
  }

  public hasFeatureLink(linkRef: LinkRef): boolean {
    return !!this.links[linkRef]
  }

  public getIssuesByLinkRef(linkRef: LinkRef): FeatureLinkIssue<TContext>[] | undefined {
    return this.links[linkRef]?.issues;
  }

  private prepare(links: FeatureLink[], issues: FeatureLinkIssue<TContext>[]): void {
    for (const link of links) {
      const linkObj: ParsedLink<TContext> = { link };
      const linkIssues = findIssueByLink<TContext>(issues, link);

      if (Array.isArray(linkIssues) && linkIssues.length > 0) {
        linkObj.issues = linkIssues;
      }

      this.links[link.rel] = linkObj;
    }
  }
}

export class FeatureLinksService {
  public createChecker<TContext extends object>(links: FeatureLink[] = [], issues: FeatureLinkIssue<TContext>[] = []): FeatureChecker<TContext> {
    return FeatureLinksService.createFeatureChecker(links, issues);
  }

  public static createFeatureChecker<TContext extends object>(links: FeatureLink[] = [], issues: FeatureLinkIssue<TContext>[] = []): FeatureChecker<TContext> {
    return new FeatureChecker(links, issues);
  }
}
