import {
  AutomatedFailoverStatus,
  AutomatedTestFailoverSchedule,
  type AutomatedTestFailoverSettings,
  type WorkloadDTO,
} from '~/services/apis/devices/dtos/WorkloadDTO';
import type { UUID } from '~/types/CommonTypes';
import { FeatureLinksService } from '~/services/FeatureLinks';

export type WORKLOAD_ID = `workload:${UUID}`;

export class Workload implements Pick<WorkloadDTO, 'id' | 'name' | 'type' | 'os'> {
  id: WORKLOAD_ID;
  name: string;
  nameDisplay: string;
  type: 'Physical' | 'VM';
  typeUsage: 'Desktop' | 'Server';
  os: 'Linux' | 'Windows';
  isOnline: boolean;
  lastBackup?: Date;
  createdAt: Date;

  updatedAt: Date;

  osVersion: string;

  lastUser: string;
  company: { id: UUID; name: string };
  macAddresses: string[] = [];
  ipAddresses: string[] = [];

  acronisIntegration?: WorkloadDTO['acronis_integration'] = {};


  constructor(dto: WorkloadDTO) {
    this.id = dto.id;
    this.name = dto.name;
    this.nameDisplay = dto.name_display;
    this.type = dto.type;
    this.typeUsage = dto.type_usage;
    this.os = dto.os;
    this.osVersion = dto.os_version;
    this.isOnline = dto.is_online;
    this.lastBackup = dto.last_backup && new Date(dto.last_backup) || undefined;
    this.createdAt = new Date(dto.created_at);
    this.updatedAt = new Date(dto.updated_at);
    this.lastUser = dto.last_user;
    this.company = structuredClone(dto.company);

    this.macAddresses = structuredClone(dto.mac_addresses);
    this.ipAddresses = structuredClone(dto.ip_addresses);

    if (dto.acronis_integration) {
      this.acronisIntegration = structuredClone(dto.acronis_integration);
    }
  }

  workLoadIcon(): string {
    switch (this.type) {
      case "Physical":
        return 'mdi-desktop-classic';
      case "VM":
        return 'mdi-cloud-outline';
    }
    return 'mdi-desktop-tower';
  }


  get osFullName(): string {
    return `${this.os} ${this.osVersion}`;
  }

  get typeFullName(): string {
    return `${this.type} ${this.typeUsage}`;
  }

  get companyName(): string {
    return this.company?.name || '';
  }

  get isAcronisSynced(): boolean {
    return Boolean(this.acronisIntegration?.workload_id);
  }
  get isDrEnabled(): boolean {
    return Boolean(this.acronisIntegration?.disaster_recovery?.protected);
  }
  get isDrAtfEnabled(): boolean {
    const automated_test_failover = this.acronisIntegration?.disaster_recovery?.recovery_server?.recovery?.automated_test_failover;
    return Boolean(
      this.isDrEnabled
      && automated_test_failover?.schedule
      && automated_test_failover.schedule !== AutomatedTestFailoverSchedule.NEVER);
  }

  get isDrAtfEnabledAndFailed(): boolean {
    const automated_test_failover = this.acronisIntegration?.disaster_recovery?.recovery_server?.recovery?.automated_test_failover;
    return Boolean(
      this.isDrEnabled
      && automated_test_failover?.schedule
      && automated_test_failover.schedule !== AutomatedTestFailoverSchedule.NEVER
      && automated_test_failover.last_status === AutomatedFailoverStatus.FAILURE,
    );
  }

  get drAtfScreenshot(): string {
    const automated_test_failover = this.acronisIntegration?.disaster_recovery?.recovery_server?.recovery?.automated_test_failover;
    return automated_test_failover?.screenshot_link || '';
  }

  get drAtfLastStatus(): { lastStatus?: AutomatedFailoverStatus; lastRun?: Date } | undefined {
    const automated_test_failover = this.acronisIntegration?.disaster_recovery?.recovery_server?.recovery?.automated_test_failover;
    if (!automated_test_failover) {
      return undefined;
    }
    return {
      lastStatus: automated_test_failover?.last_status,
      lastRun: automated_test_failover?.last_start && new Date(automated_test_failover.last_start),
    };
  }

  get drServerStatus() {
    const recovery = this.acronisIntegration?.disaster_recovery?.recovery_server;
    return recovery?.status;
  }

  get drVmStatus() {
    return this.acronisIntegration?.disaster_recovery?.recovery_server?.vm?.status;
  }

  get drVmrcLink() {
    const recoveryServer = this.acronisIntegration?.disaster_recovery?.recovery_server;
    const links = FeatureLinksService.createFeatureChecker(recoveryServer?._links||[], recoveryServer?._issues||[]);
    // return links;
    return links.links['DR_CLOUD_SERVER_REMOTE_CONSOLE_PAGE']?.link.href;
  }

  get drServerPageUiLink() {
    const recoveryServer = this.acronisIntegration?.disaster_recovery?.recovery_server;
    const links = FeatureLinksService.createFeatureChecker(recoveryServer?._links||[], recoveryServer?._issues||[]);
    // return links;
    return links.links['DR_CLOUD_SERVER_PAGE']?.link.href;
  }

  toDTO(): WorkloadDTO {
    return {
      id: this.id,
      name: this.name,
      name_display: this.nameDisplay,
      type: this.type,
      type_usage: this.typeUsage,
      os: this.os,
      os_version: this.osVersion,
      is_online: this.isOnline,
      last_backup: this.lastBackup?.toISOString(),
      last_user: this.lastUser,
      company: structuredClone(this.company),
      updated_at: this.updatedAt.toISOString(),
      created_at: this.createdAt.toISOString(),
      acronis_integration: structuredClone(this.acronisIntegration),
      mac_addresses: structuredClone(this.macAddresses),
      ip_addresses: structuredClone(this.ipAddresses),
    };
  }
}
