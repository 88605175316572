import type { PaginatedCollection } from '~/services/apis/ApiCollections';
import type { WorkloadDTO } from '~/services/apis/devices/dtos/WorkloadDTO';
import { Workload, type WORKLOAD_ID } from '~/entities/Workload';
import type { Ref } from 'vue';

export class WorkloadsApi {
  constructor(private readonly db: PouchDB.Database, private readonly companyId: string | undefined) {

  }

  filteredByCompany(companyId: string) {
    return new WorkloadsApi(this.db, companyId);
  }

  subscribeChanges() {
    const keyFilter = this.companyId ? { key: this.companyId } : {};
    return this.db.changes<{ data: WorkloadDTO }>({
      view: 'type_index/workload_by_id',
      since: 'now',
      live: true,
      include_docs: true,
      ...keyFilter,
    });
  }

  subscribeChangesAndUpdateList(workloadsList: Ref<Workload[]>) {
    return this.subscribeChanges()
      .on('change', (change) => {
        // return change;
        if (!change.doc) {
          return;
        }
        if (this.companyId && change.doc?.data?.company.id !== this.companyId) {
          return
        }
        const index = workloadsList.value.findIndex((w) => w.id === change.id);
        if (change.deleted) {
          if (index === -1) {
            return;
          }
          workloadsList.value.splice(index, 1);
          return;
        }

        const wl = new Workload((change.doc.data || {}) as WorkloadDTO);

        if (index === -1) {
          workloadsList.value.push(wl);
          return;
        }

        workloadsList.value[index] = wl;
      });
  }


  async getWorkloadsByIDs(ids: string[]): Promise<Workload[]> {
    return this.db.query<{ data: WorkloadDTO }>('type_index/workload_by_id', {
      keys: ids,
      include_docs: true,
    }).then((response) => {
      return response.rows.map((row) => new Workload(row.doc?.data as WorkloadDTO));
    });
  }
  async getWorkloads(ids?: string[]): Promise<PaginatedCollection<Workload>> {
    const limit = 1000;
    const params: { include_docs: boolean; limit: number; key?: string } = {
      include_docs: true,
      limit,
    };
    if (this.companyId) {
      params.key = this.companyId;
    }
    return this.db
      .query<{ data: WorkloadDTO }>('type_index/workload_by_id', params)
      .then((response) => {
        return {
          items: response.rows.map((row) => new Workload(row.doc?.data as WorkloadDTO)),
          pagination: {
            total: response.total_rows,
            limit,
            offset: response.offset,
          },
        };
      });
  }

  async removeAll() {
    this.db.query<{ data: WorkloadDTO }>('type_index/workload_docs', { include_docs: true })
      .then((response) => {
        return response.rows.map((row) => {
          row.doc && this.db.remove(row.doc);
        });
      });
  }

  getWorkloadByID(workloadId: WORKLOAD_ID): Promise<Workload> {
    return this.getWorkloadsByIDs([workloadId]).then((workloads) => {
      return workloads[0];
    });
  }

  saveWorkload(workload: Workload): Promise<Workload> {
    return this.db
      .get<{ data: WorkloadDTO }>(workload.id)
      .then((doc) => {
        const workloadDto = doc.data;

        Object.assign(workloadDto, workload.toDTO());

        return this.db.put({
          data: workloadDto,
          type: 'workload',
          _id: doc._id,
          _rev: doc._rev,
        }).then(() => new Workload(workloadDto));
      });
  }
}
