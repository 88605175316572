import { default as alerts3V3WLrA6PcMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/alerts.vue?macro=true";
import { default as cyber_45insurancePA0S68LKIxMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/cyber-insurance.vue?macro=true";
import { default as automated_45test_45failover_45configure9Addb2sl1BMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/automated-test-failover-configure.vue?macro=true";
import { default as automated_45test_45failoverYAREJAurNuMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/automated-test-failover.vue?macro=true";
import { default as configuretpCyVnWYbOMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/configure.vue?macro=true";
import { default as devices6GknXGdiaIMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/devices.vue?macro=true";
import { default as _91dialog_45name_93YnSLuwqNdxMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/dialogs/[dialog-name].vue?macro=true";
import { default as last_45atf_45screenshotoj6JyVURuLMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/last-atf-screenshot.vue?macro=true";
import { default as managegl2QgFo9n8Meta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/manage.vue?macro=true";
import { default as start_45failoverzscYvNpYb4Meta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/start-failover.vue?macro=true";
import { default as stop_45failover_45conffirmationUTHnmkV7T3Meta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/stop-failover-conffirmation.vue?macro=true";
import { default as disaster_45recoveryOpNUiieVMCMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery.vue?macro=true";
import { default as helpT5MiFFq5tkMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/help.vue?macro=true";
import { default as indexwE7jcec53BMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/index.vue?macro=true";
import { default as acronisep71PwPmUvMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/integrations/acronis.vue?macro=true";
import { default as integrationsqXkrEwqV6UMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/integrations.vue?macro=true";
import { default as loginsJ43xpSIxUMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/login.vue?macro=true";
import { default as settingsZn0ue4uezOMeta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/settings.vue?macro=true";
import { default as workloadsYeUHWasmN6Meta } from "/Users/evgeniy.fomin/projects/dr-integrator/pages/workloads.vue?macro=true";
export default [
  {
    name: "alerts",
    path: "/alerts",
    meta: alerts3V3WLrA6PcMeta || {},
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/alerts.vue").then(m => m.default || m)
  },
  {
    name: "cyber-insurance",
    path: "/cyber-insurance",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/cyber-insurance.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery",
    path: "/disaster-recovery",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery.vue").then(m => m.default || m),
    children: [
  {
    name: "disaster-recovery-automated-test-failover-configure",
    path: "automated-test-failover-configure",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/automated-test-failover-configure.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-automated-test-failover",
    path: "automated-test-failover",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/automated-test-failover.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-configure",
    path: "configure",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/configure.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-devices",
    path: "devices",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/devices.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-dialogs-dialogname",
    path: "dialogs/:dialogname()",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/dialogs/[dialog-name].vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-last-atf-screenshot",
    path: "last-atf-screenshot",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/last-atf-screenshot.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-manage",
    path: "manage",
    meta: managegl2QgFo9n8Meta || {},
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/manage.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-start-failover",
    path: "start-failover",
    alias: ["/workloads/dr/start-failover"],
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/start-failover.vue").then(m => m.default || m)
  },
  {
    name: "disaster-recovery-stop-failover-conffirmation",
    path: "stop-failover-conffirmation",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/disaster-recovery/stop-failover-conffirmation.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "Index",
    path: "/",
    meta: indexwE7jcec53BMeta || {},
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "integrations",
    path: "/integrations",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/integrations.vue").then(m => m.default || m),
    children: [
  {
    name: "integrations-acronis",
    path: "acronis",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/integrations/acronis.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "Login",
    path: "/login",
    meta: loginsJ43xpSIxUMeta || {},
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "workloads",
    path: "/workloads",
    meta: workloadsYeUHWasmN6Meta || {},
    component: () => import("/Users/evgeniy.fomin/projects/dr-integrator/pages/workloads.vue").then(m => m.default || m)
  }
]