import type { AcronisApiKeysDto } from '~/services/acronis/IntegrationsAcronisService';

export default defineNuxtPlugin({
  name: 'disasterRecoveryEnabled',
  dependsOn: ['integrationsDBClient'],
  async setup(app) {
    const acronisEnabled = ref<boolean>(false);
    const acronisApiKeys = ref<AcronisApiKeysDto | null>(null);
    const db = app.$integrationsDB as PouchDB.Database<
      { _id: 'acronis.enabled', value: boolean }
      | AcronisApiKeysDto
    >;
    const acronis = await db.get('acronis.enabled').catch(() => null);
    if (acronis?._id === 'acronis.enabled') {
      acronisEnabled.value = Boolean(acronis.value);
    }
    const keys = await db.get('acronis.api-keys').catch(() => null);

    if (keys?._id === 'acronis.api-keys') {
      acronisApiKeys.value = keys;
    }

    db.changes({
      since: 'now',
      live: true,
      include_docs: true,
      doc_ids: ['acronis.enabled','acronis.api-keys'],
    }).on('change', async (changedDoc) => {
      if (changedDoc.doc?._id === 'acronis.enabled') {
        acronisEnabled.value = changedDoc.doc.value;
      }else if (changedDoc.doc?._id === 'acronis.api-keys') {
        acronisApiKeys.value = changedDoc.doc;
      }
    });

    return {
      provide: {
        disasterRecoveryEnabled: acronisEnabled,
        acronisApiKeys,
      },
    };
  },
});
