import { WorkloadsApi } from '~/services/apis/devices/WorkloadsApi';
import { faker } from '@faker-js/faker';

function fakerInit() {
  return {
    id: faker.string.uuid(),
    name: faker.internet.displayName(),
    dr_enabled: faker.datatype.boolean(),
    type: faker.helpers.arrayElement(['VM', 'Physical']),
    os: faker.helpers.arrayElement(['Windows', 'Linux']),
    is_online: faker.datatype.boolean(),
    last_backup: faker.helpers.arrayElement([faker.date.past().toISOString(), undefined]),
    created_at: faker.date.past().toISOString(),
    updated_at: faker.date.past().toISOString(),
  };
}

export default defineNuxtPlugin({
  dependsOn: ['integrationsDBClient'],
  setup: async (app) => {
    // $fetch('workloadsPlugin')
    // app.vueApp.provide('workloadsApi', new WorkloadsApi({
    //   get(url: string) {
    //     console.log('__HTTP GET', url);
    //     const workloads = faker.helpers.multiple(fakerInit, { count: 25 });
    //     return Promise.resolve({
    //       data: {
    //         items: workloads,
    //       },
    //     });
    //   },
    // }));
    app.vueApp.provide('workloadsApi', new WorkloadsApi(app.$integrationsDB as PouchDB.Database, undefined));
  },
});
