import revive_payload_client_4sVQNw7RlN from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/evgeniy.fomin/projects/dr-integrator/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AOuQ1DYzjk from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import disasterRecoveryEnabled_client_25JkjylEDd from "/Users/evgeniy.fomin/projects/dr-integrator/plugins/disasterRecoveryEnabled.client.ts";
import pouchDbPlugin_client_KUmkNNRhuY from "/Users/evgeniy.fomin/projects/dr-integrator/plugins/pouchDbPlugin.client.ts";
import vuetify_7h9QAQEssH from "/Users/evgeniy.fomin/projects/dr-integrator/plugins/vuetify.ts";
import workloadsPlugin_client_GOhEwDzLYH from "/Users/evgeniy.fomin/projects/dr-integrator/plugins/workloadsPlugin.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  disasterRecoveryEnabled_client_25JkjylEDd,
  pouchDbPlugin_client_KUmkNNRhuY,
  vuetify_7h9QAQEssH,
  workloadsPlugin_client_GOhEwDzLYH
]