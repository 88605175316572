import PouchDB from 'pouchdb-browser';

export default defineNuxtPlugin({
  name: 'integrationsDBClient',
  setup: (app) => {
    // app.vueApp.provide('integrationsDB', new PouchDB('integrationsDB'));
    const db = new PouchDB(window.location.origin + '/_couchdb/integrex', {
      auth: {
        username: 'integrex.ro',
        password: 'qwe123QWE',
      },
    });
    app.vueApp.provide('integrationsDB', db);
    return { provide: { integrationsDB: db } };
  },
});
