import routerOptions0 from "/Users/evgeniy.fomin/projects/dr-integrator/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/Users/evgeniy.fomin/projects/dr-integrator/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}