import type { RouterConfig } from '@nuxt/schema';

export default {
  routes(_routes) {
    const workloads = _routes.find((route) => route.name === 'workloads');
    if (workloads) {
      workloads.children = workloads.children || [];
      workloads.children.push({
        name: 'workloads-dr-failover',
        path: '/workloads/:id/dr-start-failover',
        components: { default: () => import('~/pages/disaster-recovery/start-failover.vue') },
      });
      workloads.children.push({
        name: 'workloads-dr-stop-failover',
        path: '/workloads/:id/dr-stop-failover',
        components: { default: () => import('~/pages/disaster-recovery/stop-failover-conffirmation.vue') },
      });
      workloads.children.push({
        name: 'workloads-dr-screenshot',
        path: '/workloads/:id/dr-last-atf-screenshot',
        components: { default: () => import('~/pages/disaster-recovery/last-atf-screenshot.vue') },
        props: { default: true },
      });
    }
    return _routes;
  },
} satisfies RouterConfig;
