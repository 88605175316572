import type { DatetimeAsString, UUID } from '~/types/CommonTypes';
import type { FeatureLink, FeatureLinkIssue } from '~/services/FeatureLinks';
import type { WORKLOAD_ID } from '~/entities/Workload';

interface CompanyDTO {
  id: string;
  name: string;
}

export interface WorkloadDTO {
  id: WORKLOAD_ID;

  name: string;
  name_display: string; // human friendly name

  type: 'Physical' | 'VM';
  type_usage: 'Desktop' | 'Server';

  os: 'Linux' | 'Windows';
  os_version: string;

  is_online: boolean;
  last_user: string;
  company: Pick<CompanyDTO, 'id' | 'name'>;

  last_backup?: DatetimeAsString;
  updated_at: DatetimeAsString;
  created_at: DatetimeAsString;

  mac_addresses: string[];
  ip_addresses: string[];

  acronis_integration?: {
    workload_id?: UUID; // acronis resource id
    disaster_recovery?: {
      protected: boolean;
      recovery_server?: AcronisDrRecoveryServerDTO;
      // automated_test_failover?: AutomatedTestFailoverSettings;
    }
  },
}

// DTOs from Acronis
export interface AutomatedTestFailoverSettings {
  schedule: AutomatedTestFailoverSchedule;
  timeout?: number; // seconds
  save_as_default_timeout?: boolean;
  last_start?: Date;
  last_status?: AutomatedFailoverStatus;
  next_start?: Date;
  screenshot_link?: string;
  screenshot_preview_link?: string;
  last_task_id?: string;
}

export enum AutomatedTestFailoverSchedule {
  NEVER = 'NEVER',
  MONTHLY = 'MONTHLY'
}

export enum AutomatedFailoverStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  UNAVAILABLE = 'UNAVAILABLE',
}

export interface AutomatedTestFailoverData {
  status: AutomatedFailoverStatus;
  start?: Date;
  end?: Date;
  screenshot_link?: string;
  screenshot_preview_link?: string;
}

type AcronisWorkloadType = 'unknown'
  | 'machine'
  | 'datastore.vmwesx'
  | 'datastore_cluster.vmwesx'
  | 'virtual_appliance.vmwesx'
  | 'virtual_application.vmwesx'
  | 'virtual_center.vmwesx'
  | 'virtual_cluster.vmwesx'
  | 'virtual_data_center.vmwesx'
  | 'virtual_folder.vmwesx'
  | 'virtual_machine.vmwesx'
  | 'virtual_network.vmwesx'
  | 'virtual_resource_pool.vmwesx'
  | 'virtual_host.vmwesx'
  | 'virtual_machine.vmww'
  | 'virtual_cluster.mshyperv'
  | 'virtual_machine.mshyperv'
  | 'virtual_host.mshyperv'
  | 'virtual_appliance.mshyperv'
  | 'virtual_network.mshyperv'
  | 'virtual_folder.mshyperv'
  | 'virtual_data_center.mshyperv'
  | 'datastore.mshyperv'
  | 'virtual_machine.parallelsw'
  | 'virtual_host.parallelsw'
  | 'virtual_cluster.pcs'
  | 'virtual_machine.msvpc'
  | 'virtual_machine.msvs'
  | 'virtual_machine.pcs'
  | 'virtual_machine.xen'
  | 'virtual_machine.kvm'
  | 'virtual_machine.kvmsrv'
  | 'virtual_machine.rhev'
  | 'mssql_server'
  | 'mssql_aag_database'
  | 'mssql_aag_group'
  | 'mssql_database'
  | 'mssql_database_folder'
  | 'mssql_instance'
  | 'mssql_system_database'
  | 'exchange'
  | 'msexchange_database'
  | 'msexchange_storage_group'
  | 'msexchange_mailbox.msexchange'
  | 'msexchange_mailbox.office365'
  | 'bootable_media'
  | 'virtual_host.vmww'
  | 'virtual_host.msvpc'
  | 'virtual_host.msvs'
  | 'virtual_host.pcs'
  | 'virtual_host.xen'
  | 'virtual_host.kvm'
  | 'virtual_host.kvmsrv'
  | 'virtual_host.rhev'
  | 'virtual_machine.hci'
  | 'virtual_host.hci'
  | 'virtual_cluster.hci'
  | 'virtual_machine.scale'
  | 'virtual_host.scale'
  | 'virtual_cluster.scale'
  | 'smb_shared_folder'
  | 'virtual_machine.hv_replica'
  | 'mobile'
  | 'smtp_server'
  | 'group.computers'
  | 'group.all'
  | 'group.mssql_server'
  | 'group.mssql_instance'
  | 'group.mssql_system_databases'
  | 'group.mssql_database'
  | 'group.mssql_aag_group'
  | 'group.mssql_aag_replica'
  | 'group.mssql_aag_database'
  | 'group.msexchange_cluster'
  | 'group.msexchange_server'
  | 'group.msexchange_cas'
  | 'group.msexchange_online_server'
  | 'group.msexchange_infostore'
  | 'group.msexchange_storage_group'
  | 'group.msexchange_database'
  | 'group.msexchange_mailboxes'
  | 'group.social_resources'
  | 'group.vcenter'
  | 'group.datacenter'
  | 'group.hosts_and_clusters'
  | 'group.cluster'
  | 'group.host'
  | 'group.virtual_application'
  | 'group.resource_pool'
  | 'group.virtual_folder'
  | 'group.vms_and_templates'
  | 'group.vms_folder'
  | 'group.smtp_servers';

type AttributeKV<K extends string, V> = { key: K, value: V };

type AcronisWorkloadAttributeAgent = {
  name: "agent";
  kvs: (
    AttributeKV<"mac_addresses", string[]>
    | AttributeKV<"residental_addresses", string[]>
    | AttributeKV<"os_family", string[]>
    | AttributeKV<"os_arch", string[]>
    )[];
};
type AcronisWorkloadAttributeDisasterRecovery = {
  name: "disaster_recovery";
  kvs: [AttributeKV<"protected", boolean>];
};
export interface AcronisWorkloadDTO {
  id: UUID;
  type: AcronisWorkloadType;
  name: string;
  agent_id: UUID;
  user_defined_name: string;


  last_backup?: DatetimeAsString;
  updated_at: DatetimeAsString;
  created_at: DatetimeAsString;

  attributes: (AcronisWorkloadAttributeAgent | AcronisWorkloadAttributeDisasterRecovery)[];
}

export interface AcronisDrRecoveryServerDTO {
  uuid: UUID;
  name: string;
  status: 'CREATING'
    | 'DELETING'
    | 'FAILING_BACK'
    | 'FAILBACK_DATA_TRANSFER'
    | 'FAILBACK_SWITCHOVER'
    | 'FAILBACK_VALIDATION'
    | 'FAILOVER_PROD'
    | 'FAILOVER_TEST'
    | 'FAILOVER_TEST_AUTO'
    | 'FINALIZATION'
    | 'FINALIZATION_FAILED'
    | 'RESTORING_FROM_BACKUP'
    | 'POWERING_OFF'
    | 'POWERING_ON'
    | 'RECONFIGURING'
    | 'STAND_BY'
    | 'STARTING_FAILBACK'
    | 'STARTING_FAILBACK_DATA_TRANSFER_RESUME'
    | 'STARTING_FAILBACK_DATA_TRANSFER_STOP'
    | 'STARTING_FAILBACK_SWITCHOVER_BEGIN'
    | 'STARTING_FAILBACK_SWITCHOVER_COMPLETE'
    | 'STARTING_FAILBACK_COMMIT'
    | 'STARTING_FAILBACK_ROLLBACK'
    | 'STARTING_FAILOVER_PROD'
    | 'STARTING_FAILOVER_TEST'
    | 'STARTING_FAILOVER_TEST_AUTO'
    | 'STOPPING_FAILOVER'
    | 'STOPPING_RESTORE_FROM_BACKUP'
    | 'X-FAILOVER_PROD_PREPARE'
    | 'X-FAILOVER_TEST_PREPARE';

  // vm_status: ;
  vm: {
    cpu: number;
    ram_mb: number;
    status: 'NOT_READY' | 'STARTED' | 'STOPPED' | 'SUSPENDED';
    os: string;
  };
  type: 'RECOVERY' | 'PRIMARY';
  task_uuid?: UUID;
  recovery: {
    // source: UUID;
    resource_id: UUID;
    automated_test_failover: AutomatedTestFailoverSettings;
  };
  _links: FeatureLink[];
  _issues: FeatureLinkIssue<{}>[];
}
